import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-anotation"
export default class extends Controller {
  static targets = ["canvas", "image", "button", "buttonLabel", "card"];
  static values = { index: { type: Number, default: 0 } }

  connect() {
    if (this.imageTarget.complete) {
      this.initCanvas();
    }
  }

  initCanvas() {
    this.canvas = this.canvasTarget;
    this.canvas.width = this.imageTarget.naturalWidth;
    this.canvas.height = this.imageTarget.naturalHeight;

    const fontSize = 24 * (this.imageTarget.naturalWidth / this.imageTarget.width)
    this.canvasCtx = this.canvas.getContext("2d");
    this.canvasCtx.font = "bold " + fontSize + "px Arial";
    this.canvasCtx.strokeStyle = "rgb(255, 255, 255)";
    this.canvasCtx.lineWidth = 3;
    this.canvasCtx.fillStyle = "rgb(0, 0, 0)";
  }

  swapCards(event) {
    this.indexValue = event.currentTarget.value.slice(-1) - 1;;

    this.cardTargets.forEach((card, i) => {
      if (i === this.indexValue) {
        this.cardTargets[i].removeAttribute("hidden");
      } else {
        this.cardTargets[i].setAttribute("hidden", true);
      }
    });
  }

  setCoordinates(event) {
    const currentClickPosX = event.offsetX + 1;
    const currentClickPosY = event.offsetY + 1;
    const currentWidth = this.imageTarget.width;
    const currentHeight = this.imageTarget.height;
    const naturalWidth = this.imageTarget.naturalWidth;
    const naturalHeight = this.imageTarget.naturalHeight;

    this.currentXInput.value = ((naturalWidth / currentWidth) * currentClickPosX).toFixed(0);
    this.currentYInput.value = ((naturalHeight / currentHeight) * currentClickPosY).toFixed(0);
    this.redrawCanvas();
  }

  redrawCanvas() {
    this.canvasCtx.clearRect(0,0,this.canvas.width,this.canvas.height);

    this.cardTargets.forEach((card, i) => {
      const x = parseInt(document.getElementById("x_" + (i + 1)).value);
      const y = parseInt(document.getElementById("y_" + (i + 1)).value);

      if (x >= 0 && y >= 0 ) {
        this.checkColorsSetForIndex(i);
        this.drawNumberOnCanvas(i + 1, x, y);
      }
    });
  }

  checkColorsSetForIndex(i) {
    this.buttonLabelTargets[i].classList.remove("text-warning");
    this.canvasCtx.fillStyle = "rgb(0, 0, 0)";

    if(document.getElementById("name_" + (i + 1)).value == '' || document.getElementById("colors_" + (i + 1)).value == '') {
      this.buttonLabelTargets[i].classList.add("text-warning");
      this.canvasCtx.fillStyle = "rgb(230, 163, 7)";
    }
  } 

  drawNumberOnCanvas(number, x, y) {
    x = x - 8;
    y = y + 6;

    this.canvasCtx.strokeText(number, x, y);
    this.canvasCtx.fillText(number, x, y);
  }

  get currentXInput() {
    return document.getElementById("x_" + (this.indexValue + 1))
  }

  get currentYInput() {
    return document.getElementById("y_" + (this.indexValue + 1))
  }
}
